<script setup>
const { formatUSD, getDaysLeft, getTimeLeft } = useFormatters()
const staticURLAdimg = useRuntimeConfig().public.staticURLAdimg
const staticURLImg = useRuntimeConfig().public.staticURLImg

// Props for deal
const props = defineProps({
    deal: {
        type: Object,
        default: null,
    },
})
</script>

<template>
    <v-card
        variant="flat"
        :class="`d-flex flex-column st-card st-box-shadow ${deal.gtm}`"
        link
        :href="deal.url"
        target="_blank"
        rel="noopener"
        data-adspot="deals-page-card"
        :data-adbrand="deal.brand.name"
        :data-adtitle="deal.title"
    >
        <v-img
            id="cardImg"
            :src="deal.isInternal == '1' ? staticURLImg + deal.img : staticURLAdimg + deal.img"
            :alt="deal.title"
        >
            <v-container class="px-2 pt-0 d-flex justify-start align-start">
                <v-spacer></v-spacer>
                <span class="pt-1">
                    <v-chip density="compact" size="small" class="bg-grey-lighten-3 text-caption font-weight-medium">
                        {{ deal.category.name }}
                    </v-chip>
                </span>
            </v-container>
        </v-img>
        <v-row no-gutters>
            <v-col>
                <span class="mt-n4 mr-2 d-flex justify-end">
                    <v-chip v-if="deal.salePrice > 0" color="secondary" variant="flat" class="st-box-shadow">
                        <span class="text-button">{{ formatUSD(deal.salePrice) }}</span>
                    </v-chip>
                    <v-chip v-else color="secondary" variant="flat" class="st-box-shadow">
                        <span class="text-button">FREE</span>
                    </v-chip>
                    <!-- <v-avatar size="64" class="bg-secondary st-box-shadow">
                        <span class="text-h6 st-text-shadow text-uppercase">{{ deal.discount }}</span>
                    </v-avatar> -->
                </span>
            </v-col>
        </v-row>
        <v-card-subtitle v-if="getTimeLeft(deal.endsAt).days < 60">
            <v-chip
                v-if="getTimeLeft(deal.endsAt).days > 0"
                density="compact"
                size="small"
                class="mr-1 font-weight-medium"
            >
                <span v-if="getTimeLeft(deal.endsAt).days > 1">{{ getTimeLeft(deal.endsAt).days }} days</span>
                <span v-else>{{ getTimeLeft(deal.endsAt).days }} day</span>
            </v-chip>
            <template v-else>
                <v-chip
                    v-if="getTimeLeft(deal.endsAt).hours > 0"
                    density="compact"
                    size="small"
                    class="mr-1 font-weight-medium"
                >
                    {{ getTimeLeft(deal.endsAt).hours }} hours
                </v-chip>
                <v-chip v-else density="compact" size="small" class="font-weight-medium">
                    {{ getTimeLeft(deal.endsAt).minutes }} min
                </v-chip>
            </template>
        </v-card-subtitle>
        <v-card-subtitle v-else>
            <v-chip density="compact" size="small" class="mr-1 font-weight-medium"> Forever </v-chip>
        </v-card-subtitle>
        <v-card-text class="pt-3 pb-0 text-blue-darken-2 font-weight-medium">
            {{ deal.brand.name }}
        </v-card-text>
        <v-card-title class="pb-1">
            <div class="text-break text-wrap" style="font-size: 1.1rem !important; font-weight: 600 !important">
                {{ deal.title }}
            </div>
        </v-card-title>
        <v-card-subtitle class="pt-0 pb-4 font-weight-medium">
            <v-row>
                <v-col>
                    <div class="st-two-lines">{{ deal.description }}</div>
                </v-col>
            </v-row>
        </v-card-subtitle>
        <v-card-text class="text-subtitle-2 font-weight-medium">
            <span v-if="deal.listPrice > 0" class="text-decoration-line-through text-grey-darken-2">
                {{ formatUSD(deal.listPrice) }}
            </span>
            <span v-if="deal.salePrice > 0" class="mx-2 px-2 rounded-pill">
                {{ deal.discount }}
            </span>
            <span v-if="deal.salePrice > 0" class="text-no-wrap text-green-darken-3 rounded-pill">
                Save {{ formatUSD(deal.listPrice - deal.salePrice) }}
            </span>
            <!-- <span v-else class="px-2 bg-secondary rounded-pill" :class="deal.listPrice > 0 ? `ml-2` : `ml-0`">
                FREE
            </span> -->
        </v-card-text>
    </v-card>
</template>
<style scoped>
.st-polygon-left-point {
    -webkit-clip-path: polygon(8% 0%, 100% 0%, 100% 100%, 8% 100%, 0% 50%);
    clip-path: polygon(8% 0%, 100% 0%, 100% 100%, 8% 100%, 0% 50%);
}
</style>
